<!--
 * @Author: wang peng
 * @Date: 2023-2-28
 * @Description: 订单管理-历史订单-订单详情
-->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="real-time-detail">
      <div class="flexConent">
        <ykc-detail
          :isLine="true"
          :tips="tips"
          :tags="tags"
          :titles="[`订单号：${dealData(detailData.tradeSeq)}`]"
          :className="'left'">
          <div slot="cvBody">
            <div class="form-detail flex">
              <ykc-form :label-position="'left'" label-width="130px" class="list-left">
                <ykc-detail-item :label="'充电用户：'">
                  <span>{{ dealData(detailData.userAccount) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'用户姓名：'">
                  <span>{{ dealData(detailData.nickName) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'充电卡号：'">
                  <span>{{ dealData(detailData.cardNo) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'机构名称：'">
                  <span>{{ dealData(detailData.companyName) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'车牌号码：'">
                  <span>{{ dealData(detailData.plateNumber) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'电站商户：'">
                  <span>{{ dealData(detailData.stationOperatorName) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'充电场站:'">
                  <span>{{ dealData(detailData.stationName) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'城市：'">
                  <span>{{ dealData(detailData.address) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'终端名称：'">
                  <span>{{ dealData(detailData.name) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'终端编码：'">
                  <span>{{ dealData(gunCode || detailData.gunCode) }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'电桩类型：'">
                  <span>{{ dealData(detailData.pileType) == 1 ? '直流' : '交流' }}</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'电桩功率：'">
                  <span>{{ dealData(detailData.pilePower) }}KW</span>
                </ykc-detail-item>
                <ykc-detail-item :label="'充电类型：'" class="last-list">
                  <span>
                    {{
                      detailData.chargingType === 1
                        ? '有序充电'
                        : detailData.chargingType === 0
                        ? '正常充电'
                        : '——'
                    }}
                  </span>
                </ykc-detail-item>
                <template v-if="isShare">
                  <ykc-detail-item :label="'建桩联系人：'">
                    <span>{{ dealData(detailData.pileName) }}</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'建桩联系人手机号：'" class="last-list">
                    <span>{{ dealData(detailData.pilePhone) }}</span>
                  </ykc-detail-item>
                </template>
              </ykc-form>
            </div>
          </div>
        </ykc-detail>
        <div class="right-boxs">
          <ykc-detail :tips="false" :titles="['充电数据']">
            <div class="card-content" slot="cvBody">
              <div class="card">
                <div class="time-content">
                  <div class="time-num">
                    {{ detailData.userActualIncome }}
                  </div>
                  <div class="time-title">用户实付（元）</div>
                </div>
                <ykc-icon name="chargingTime"></ykc-icon>
              </div>
              <div class="card">
                <div class="time-content2">
                  <div class="time-num">
                    {{ detailData.operatorActualIncome }}
                  </div>
                  <div class="time-title">商户实收（元）</div>
                </div>
                <ykc-icon name="charging-degree"></ykc-icon>
              </div>
              <div class="card">
                <div class="time-content2">
                  <div class="time-num">
                    {{ detailData.chargedPower }}
                  </div>
                  <div class="time-title">充电电量（kw·h）</div>
                </div>
                <ykc-icon name="amount"></ykc-icon>
              </div>
            </div>
          </ykc-detail>
          <ykc-detail
            :tips="tips"
            :isLine="true"
            :titles="[`其他信息`]"
            :className="'left cardview-box-content'">
            <div slot="cvBody">
              <div class="form-detail flex">
                <ykc-form :label-position="'left'" label-width="100px" class="list-left">
                  <ykc-detail-item :label="'SOC区间：'">
                    <span>{{ dealData(detailData.qjSoc) }}%</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'充电模式：'">
                    <span>{{ dealData(detailData.multipleChargeName) }}</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'启动时长：'">
                    <span>{{ dealData(detailData.startChargedTime) }}s</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'订单结束原因：'">
                    <span>{{ stopChargeReasonType }}</span>
                  </ykc-detail-item>
                  <ykc-detail-item></ykc-detail-item>
                  <ykc-detail-item></ykc-detail-item>
                  <ykc-detail-item></ykc-detail-item>
                </ykc-form>
                <ykc-form :label-position="'left'" label-width="95px" class="list-left">
                  <ykc-detail-item :label="'识别Vin：'">
                    <span>{{ dealData(detailData.vinCode) }}</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'绑定Vin：'">
                    <span>{{ dealData(detailData.carVinCode) }}</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'流量互联单号：'">
                    <span>{{ dealData(detailData.zdlTradeSeq) }}</span>
                  </ykc-detail-item>
                  <ykc-detail-item :label="'桩联互联单号：'">
                    <span>{{ dealData(detailData.pileTradeSeq) }}</span>
                  </ykc-detail-item>
                </ykc-form>
              </div>
            </div>
          </ykc-detail>
        </div>
      </div>
      <!-- 用户支付信息/商户收款信息 start -->
      <div class="flexConent">
        <ykc-detail
          :tips="tips"
          :titles="['用户支付信息']"
          :tags="[{ type: 'tt2', name: `${payFun}` }]"
          class="table-body left"
          ref="headRight">
          <div slot="cvBody" class="bottom-content">
            <div class="list" ref="bottomCardList">
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">订单总金额</div>
                  <div class="detail-tips">
                    <div>电站原价及度数计算出的费用</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.chargedAmount) }}</p>
                      <p>{{ dealData(detailData.parkedAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">商户活动</div>
                  <div class="detail-tips">
                    <div>{{ dealData(detailData.userSettleInfoVo?.shActivityName) }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.userSettleInfoVo?.shActivityChargedAmount) }}</p>
                      <p>{{ dealData(detailData.userSettleInfoVo?.shActivityServiceAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">平台活动</div>
                  <div class="detail-tips">
                    <div>{{ dealData(detailData.userSettleInfoVo?.ptActivityName) }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.userSettleInfoVo?.ptActivityChargedAmount) }}</p>
                      <p>
                        {{ dealData(detailData.userSettleInfoVo?.ptActivityServiceAmount) }}
                      </p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">
                    会员权益抵扣
                    <div class="tag" v-show="detailData.userSettleInfoVo?.memberLevel !== null">
                      <div class="coupon">LV{{ detailData.userSettleInfoVo?.memberLevel }}</div>
                    </div>
                  </div>
                  <div class="detail-tips">
                    <div>{{ dealData(detailData.userSettleInfoVo?.memberServiceName) }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.userSettleInfoVo?.memberChargedAmount) }}</p>
                      <p>{{ dealData(detailData.userSettleInfoVo?.memberServiceAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">红包抵扣</div>
                  <div class="detail-tips">
                    <div>{{ dealData(detailData.userSettleInfoVo?.redPackTempName) }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>
                        {{ dealData(detailData.userSettleInfoVo?.redPackDiscountAmount) }}
                      </p>
                    </div>
                    <div class="right-money">
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">
                    优惠卡券抵扣
                    <div class="tag" v-show="detailData.userSettleInfoVo?.yhCardType !== null">
                      <div class="coupon">
                        {{
                          detailData.userSettleInfoVo?.yhCardType == 1
                            ? '平台抵用卡'
                            : detailData.userSettleInfoVo?.yhCardType == 2
                            ? '平台优惠券'
                            : detailData.userSettleInfoVo?.yhCardType == 3
                            ? '商户抵用卡'
                            : detailData.userSettleInfoVo?.yhCardType == 4
                            ? '商户优惠券'
                            : ''
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="detail-tips">
                    <div>{{ dealData(detailData.userSettleInfoVo?.yhCardName) }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.userSettleInfoVo?.yhCardChargedAmount) }}</p>
                      <p>{{ dealData(detailData.userSettleInfoVo?.yhCardServiceAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="list-item">
                <template v-if="recordPayList.length === 0">
                  <div class="detail-left">
                    <div class="detail-title emphasize">用户实付</div>
                    <div class="detail-tips">
                      <div>
                        电费：
                        <span class="blank">
                          {{ detailData.chargedAmountAfterDiscount }}
                        </span>
                        元
                        <span class="space">
                          服务费：
                          <span class="blank">
                            {{ dealData(detailData.parkedAmountAfterDiscount) }}
                          </span>
                          元
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="detail-right">
                    <div class="totla">
                      {{ dealData(detailData.userActualIncome) }}
                      <span style="letter-spacing: -0.17px">元</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="detail-left">
                    <div class="detail-title emphasize">用户实付</div>
                    <div class="detail-tips">
                      <div>
                        <span
                          v-for="(item, index) in recordPayList"
                          :key="index"
                          style="margin-right: 20px">
                          <span v-if="item.freePayType !== 0">
                            <span v-if="String(item.freePayType) === '1'">
                              微信：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.freePayType) === '2'">
                              支付宝：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.freePayType) === '3'">
                              微信：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.freePayType) === '4'">
                              支付宝：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                          </span>
                          <span v-if="item.freePayType == 0">
                            <span v-if="String(item.payType) == '1'">
                              个人钱包：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.payType) == '2'">
                              企业钱包：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.payType) == '3'">
                              企业额度：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.payType) == '5'">
                              红包钱包：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="detail-right">
                    <div class="totla">
                      {{ dealData(allDetail.userActualIncome) }}
                      <span style="letter-spacing: -0.17px">元</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </ykc-detail>
        <ykc-detail
          :tips="tips"
          :titles="['商户收款信息']"
          class="table-body right"
          ref="headRight">
          <div slot="cvBody" class="bottom-content">
            <div class="list" ref="bottomCardList">
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">订单总金额</div>
                  <div class="detail-tips">
                    <div>电站原价及度数计算出的费用</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.chargedAmount) }}</p>
                      <p>{{ dealData(detailData.parkedAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">商户活动</div>
                  <div class="detail-tips">
                    <div>{{ dealData(detailData.userSettleInfoVo?.shActivityName) }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.userSettleInfoVo?.shActivityChargedAmount) }}</p>
                      <p>{{ dealData(detailData.userSettleInfoVo?.shActivityServiceAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">
                    优惠卡券抵扣
                    <div class="tag" v-show="detailData.userSettleInfoVo?.shCardType != null">
                      <div class="coupon">
                        {{
                          detailData.userSettleInfoVo?.shCardType == 1
                            ? '平台抵用卡'
                            : detailData.userSettleInfoVo?.shCardType == 2
                            ? '平台优惠券'
                            : detailData.userSettleInfoVo?.shCardType == 3
                            ? '商户抵用卡'
                            : detailData.userSettleInfoVo?.shCardType == 4
                            ? '商户优惠券'
                            : ''
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="detail-tips">
                    <div>{{ detailData.userSettleInfoVo?.shCardName }}</div>
                  </div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.userSettleInfoVo?.shCardChargedAmount) }}</p>
                      <p>{{ dealData(detailData.userSettleInfoVo?.shCardServiceAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">平台引流抽成</div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.commissionChargedAmount) }}</p>
                      <p>{{ dealData(detailData.commissionServiceAmount) }}</p>
                    </div>
                    <div class="right-money">
                      <p>电&nbsp;&nbsp;&nbsp;费(元)</p>
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item" v-if="isShare">
                <div class="detail-left">
                  <div class="detail-title">私桩共享抽成</div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.commissionPrivateShare) }}</p>
                    </div>
                    <div class="right-money">
                      <p>服务费(元)</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <div class="detail-left">
                  <div class="detail-title">微信商户</div>
                </div>
                <div class="detail-right">
                  <div class="money">
                    <div class="left-money">
                      <p>{{ dealData(detailData.mchName) }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-item">
                <template v-if="recordPayList.length === 0">
                  <div class="detail-left">
                    <div class="detail-title emphasize">运营商实收</div>
                    <div class="detail-tips">
                      <div>
                        电费：
                        <span class="blank">
                          {{ dealData(detailData.operatorElecActualIncome) }}
                        </span>
                        元
                        <span class="space">
                          服务费：
                          <span class="blank">
                            {{ dealData(detailData.operatorServActualIncome) }}
                          </span>
                          元
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="detail-right">
                    <div class="totla">
                      {{ dealData(detailData.operatorActualIncome) }}
                      <span style="letter-spacing: -0.17px">元</span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="detail-left">
                    <div class="detail-title emphasize">用户实付</div>
                    <div class="detail-tips">
                      <div>
                        <span
                          v-for="(item, index) in recordPayList"
                          :key="index"
                          style="margin-right: 20px">
                          <span v-if="item.freePayType !== 0">
                            <span v-if="String(item.freePayType) === '1'">
                              微信：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.freePayType) === '2'">
                              支付宝：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.freePayType) === '3'">
                              微信：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.freePayType) === '4'">
                              支付宝：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                          </span>
                          <span v-if="item.freePayType == 0">
                            <span v-if="String(item.payType) == '1'">
                              个人钱包：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.payType) == '2'">
                              企业钱包：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.payType) == '3'">
                              企业额度：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                            <span v-if="String(item.payType) == '5'">
                              红包钱包：
                              <span class="blank">{{ dealData(item.paiedAmount) }}</span>
                              元
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="detail-right">
                    <div class="totla">
                      {{ dealData(allDetail.userPaidAmount) }}
                      <span style="letter-spacing: -0.17px">元</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </ykc-detail>
      </div>
      <!-- 用户支付信息/商户收款信息 end -->
      <!-- 充电明细 start -->
      <ykc-detail
        :tips="tips"
        :isLine="true"
        :titles="['充电明细']"
        class="table-body"
        ref="headLeft_three">
        <div slot="cvBody">
          <ykc-table :data="chargedDetail" :columns="tableColumns" v-if="chargedDetail.length != 0">
            <div slot="pagination" v-if="chargedDetail.length != 0">
              <div class="botton-table">
                <div class="sum_time">总计（总时长：{{ detailData.chargedTime }}）</div>
                <div class="sum_chargedPower">{{ detailData.chargedPower }}</div>
                <div class="sum_orderAmountSeg">{{ detailData.totalAmount }}</div>
              </div>
            </div>
          </ykc-table>
          <div class="charge-empty" v-else>
            <img src="../../../assets/images/no-data.png" />
            <span>暂无数据</span>
          </div>
        </div>
      </ykc-detail>
      <!-- 充电明细 end -->
      <!-- 充电流程 start -->
      <ykc-detail class="headLeft_one" :titles="['充电流程']" :tips="tips" ref="headLeft_two">
        <div slot="cvBody">
          <div class="steps">
            <div class="card-wrap">
              <div class="steps-card">
                <div class="card-item">准备充电</div>
              </div>
              <div class="card-time">{{ dealData(detailData.preparedTime) }}</div>
            </div>
            <div class="steps-line"></div>
            <div class="card-wrap">
              <div class="steps-card">
                <div class="card-item">开始充电</div>
              </div>
              <div class="card-time">{{ dealData(detailData.startTime) }}</div>
            </div>
            <div class="steps-line"></div>
            <div class="card-wrap">
              <div class="steps-card">
                <div class="card-item">结束充电</div>
              </div>
              <div class="card-time">{{ dealData(detailData.endTime) }}</div>
            </div>
            <div class="steps-line"></div>
            <div class="card-wrap">
              <div class="steps-card">
                <div class="card-item">订单支付</div>
              </div>
              <div class="card-time">{{ dealData(detailData.tradeTime) }}</div>
            </div>
          </div>
        </div>
      </ykc-detail>
      <!-- 充电流程 end -->
    </div>
    <share-chart
      v-if="detailData.startTime"
      :title="'状态监测'"
      :tradeNo="detailData.tradeSeq"
      :page="'historicalDetail'"
      :startTime="detailData.startTime"
      :endTime="detailData.endTime"></share-chart>
  </scroll-layout>
</template>

<script>
  import { localGetItem } from '@/utils';
  import { orderPage } from '@/service/apis';
  import shareChart from '../OrderShareChart2.vue';

  export default {
    name: 'historicalOrderDetail',
    components: {
      shareChart,
    },
    data() {
      return {
        tips: false,
        tradeSeq: '',
        gunCode: '',
        detailData: {},
        orderDetail: {},
        tags: [],
        recordPayList: [],
        chargedDetail: [],
        chargedTimeStr: [],
        chargedHourStr: [],
        titles: ['订单号', '118298292992'],
        tableColumns: [
          { label: '充电时段', prop: 'time' },
          { label: '电价类型', prop: 'feeType' },
          { label: '电费单价(元/度)', prop: 'chargedPrice' },
          { label: '服务费单价(元/度)', prop: 'servicePrice' },
          { label: '充电度数(度)', prop: 'chargedPower' },
          { label: '费用(元)', prop: 'orderAmount' },
        ],
        isShare: false, // 是否是私桩共享订单
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
      payFun() {
        let str = '';
        const useType = localGetItem('dictionary')?.pay_type || [];
        useType.forEach(item => {
          if (item.code === this.detailData.payType) {
            str = item.name;
          }
        });
        return str;
      },
      stopChargeReasonType() {
        const typeData = localGetItem('dictionary')?.stop_charge_reason || [];
        const typeName =
          typeData.find(item => item.code === this.detailData.stopChargeReason)?.name ?? '——';
        return typeName;
      },
    },
    created() {
      this.recordId = this.$route.query.recordId;
      this.isShare = this.$route.path.includes('privatePileShare');
      // 迁桩后老订单显示老终端编码
      this.gunCode = this.$route.query.gunCode;
      console.log('this.$route', this.$route);
      this.getDetail();
    },
    methods: {
      /**
       * 获取详情数据
       */
      getDetail() {
        orderPage
          .historyChargingOrderInfo({ recordId: this.recordId })
          .then(res => {
            console.log('请求详情数据+++++', res);
            this.detailData = res;
            this.chargedDetail = res.chargeDetails;
            const cls = [
              { id: 1, name: '扫码启动' },
              { id: 2, name: '扫码启动' },
              { id: 3, name: '刷卡充电' },
              { id: 4, name: '即插即充' },
              { id: 5, name: '流量方' },
              { id: 6, name: '预约充电' },
              { id: 7, name: '离线卡' },
            ][Number(this.detailData?.chargingSource) - 1];

            // 结算方式
            const settleTypeMap = {
              0: '正常结算',
              1: '异常单手动结算',
              2: '异常单自动结算',
              3: '平台延迟结算',
              4: '三方延迟结算',
            };
            const settleType = settleTypeMap[this.detailData?.settleType] ?? '一';
            // 订单类型
            const orderTypeMap = {
              0: '直联订单',
              1: '流量订单',
              2: '桩联订单',
              3: '双向互联',
            };
            const orderType = orderTypeMap[this.detailData?.orderType] ?? '一';

            this.tags = [
              {
                type: 'tt2',
                name: `${cls?.name || '一'}`,
              },
              {
                type: 'tt2',
                name: `${settleType}`,
              },
              {
                type: 'tt2',
                name: `${orderType}`,
              },
            ];
            if (this.isShare) {
              this.tags.push({
                type: 'tt2',
                name: `私桩共享订单`,
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       *
       */
      dealData(value) {
        console.log('data+++', value);
        if (value !== undefined && value !== null && value !== '') {
          return value;
        }
        return '——';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .real-time-detail {
    .flexConent {
      margin-bottom: 16px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .cardview-body {
        .bottom-content {
          .el-scrollbar__wrap {
            .el-scrollbar__view {
              .list {
                min-width: 200px !important;
              }
            }
          }
        }
      }
      .right-boxs {
        margin-left: 16px;
        flex: 1;

        .card-content {
          display: flex;
          padding-bottom: 10px;

          .card {
            width: 32%;
            height: 187px;
            margin-right: 20px;
            border: 1px solid #ebebf0;
            border-radius: 2px;
            overflow: hidden;
            position: relative;
            background: rgba(55, 115, 252, 0.1);

            .date {
              width: 152px;

              font-family: PingFangSC-Medium;
              font-size: 12px;
              color: #ffffff;
              letter-spacing: -0.17px;
              text-align: left;
              background: #3773fc;
              border-bottom-right-radius: 10px;
              padding: 1px 8px;
            }

            .time-content {
              letter-spacing: 0;
              text-align: center;
              margin-top: 56px;

              .time-num {
                font-size: 28px;
                color: #3773fc;

                .unit {
                  font-size: 14px;
                }
              }

              .time-title {
                font-size: 14px;
                color: #000000;
                margin-top: 18px;
              }
            }

            .time-content2 {
              letter-spacing: 0;
              text-align: center;
              margin-top: 56px;

              .time-num {
                font-size: 28px;
                color: #3773fc;

                .unit {
                  font-size: 14px;
                }
              }

              .time-title {
                font-size: 14px;
                color: #000000;
                margin-top: 18px;
              }
            }
          }

          .card:last-child {
            margin-right: 0;
          }
        }
        .cardview-box-content {
          width: calc(97.4%);
          margin-top: 16px;
        }
      }
    }

    .button-group {
      margin-bottom: 10px;

      .ykc-button {
        margin-left: 8px;
      }
    }

    .last-list {
      .el-form-item {
        margin-bottom: 0;
      }
    }

    .cardview-box {
      .cardview-head {
        margin-top: 0px;
        padding-left: 10px;

        .cardview-head-box {
          margin: 17px 0;
        }

        .cardview-body {
          margin: -10px;
        }
      }

      .table-body .cardview-body {
        padding: 0 0 20px 0;
        margin: 0 -10px;
      }
    }

    .ykc-icon-power:before,
    .ykc-icon-chargingTime:before,
    .ykc-icon-soc:before,
    .ykc-icon-chargingTime:before,
    .ykc-icon-charging-degree:before {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 72px;
      color: rgb(55, 115, 252);
      opacity: 0.096;
    }
    .ykc-icon-amount:before {
      position: absolute;
      bottom: 0;
      right: -2px;
      font-size: 72px;
      color: rgb(55, 115, 252);
      opacity: 0.096;
    }
    .steps {
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding-bottom: 10px;

      .card-wrap {
        text-align: center;

        .steps-card {
          margin: 0 10px;

          .card-item {
            min-width: 48px;
            font-size: 12px;
            padding: 4px 18px;
            color: #4d8cfd;
            letter-spacing: -0.17px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border: 1px solid;
            border-radius: 2px;
            font-weight: 600;

            &::after {
              content: '';
              background: #4d8cfd;
              width: 100%;
              height: 100%;
              opacity: 0.1;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }

        .card-time {
          color: #4a4a4a;
          font-size: 12px;
          margin-top: 13px;
          font-weight: 600;
        }
      }

      .steps-line {
        width: 14%;
        margin: 11px 0px;
        background-color: inherit;
        height: 2px;
        background-image: linear-gradient(to right, #000000 0%, #000000 50%, transparent 0%);
        background-size: 5px 1px;
        background-repeat: repeat-x;
        border-width: 0;
        border-style: solid;
        border-color: inherit;
        transition: 0.15s ease-out;
        box-sizing: border-box;
      }
    }
    .list-item {
      border-bottom: 1px dashed #f1f0f0;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .detail-left {
        .detail-title {
          font-size: 12px;
          display: flex;

          .tag {
            background: #fff2e0;
            color: #ff5521;
            font-size: 16px;
            transform: scale(0.5);
            padding: 1px 8px;
            transform-origin: left;
            margin-left: 10px;
            margin-top: -6px;
            .coupon {
              font-size: 22px;
            }
          }
        }

        .detail-tips {
          text-align: left;

          div {
            color: #9b9b9b;
            font-size: 12px;
            transform: scale(0.83);
            transform-origin: left;
            margin-top: 4px;
          }

          .blank {
            color: #000000;
            font-weight: 400;
          }

          .space {
            margin-left: 20px;
          }

          .blue {
            color: #4d8cfd;
            font-weight: 400;
          }
        }
      }

      .detail-right {
        .money {
          font-size: 12px;
          color: #000000;

          div {
            display: inline-block;

            p {
              margin: 0;
            }
          }

          .left-money {
            text-align: right;
            margin-right: 4px;
          }

          .right-money {
            color: #9b9b9b;
            text-align: left;
            margin-left: 4px;
          }
        }

        .totla {
          font-size: 14px;
          color: #000000;
          font-weight: 600;
        }

        .totla-blue {
          font-size: 14px;
          color: #4d8cfd;
          font-weight: 600;
        }
      }

      .emphasize {
        font-weight: 600;
      }
    }
    .charge-empty {
      margin: 0 auto;
      text-align: center;
      padding-bottom: 20px;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      > img {
        width: 100px;
      }

      span {
        display: block;
        font-size: 12px;
        color: #88b0ff;
        text-align: center;
      }
    }
    .headLeft_one {
      margin: 16px 0;
    }

    .botton-table {
      display: table;
      width: 100%;
      font-size: 12px;
      font-weight: 700;
      color: #4d8cfd;
      .sum_time {
        display: table-cell;
        width: 60%;
        padding-left: 30px;
      }

      .sum_chargedPower {
        display: table-cell;
        width: 20%;
        padding-left: 8px;
      }

      .sum_orderAmountSeg {
        display: table-cell;
        width: 20%;
        padding-left: 8px;
      }
    }
  }
</style>
